<template>
  <div class="px-3 vx-card no-scroll-content calendar-header">
    <HistoricalDataSidebar
      :isSidebarActive="isActive"
      :data="chartData"
      :table="sidebarTableData"
      :info="sidebarInfo"
      @close-sidebar="toggleSidebar"
    />
    <CalendarHeader>
      <!-- <template slot="views">
        <div class="ml-3"></div>
        <div class="ml-3"></div>
      </template> -->

      <template slot="views">
        <div class="flex">
          <p class="p-6"></p>
        </div>
      </template>
      <template slot="month">
        <div class="flex items-center">
          <div class="flex justify-around">
            <div class="flex items-center cursor-pointer" @click="setWeek(-7)">
              <feather-icon
                icon="ChevronLeftIcon"
                svgClasses="w-6 h-6 m-1"
                class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                title="Previous Week"
              />
              <span class="fsize14 mob-fsize13 hover:underline"
                >Previous Week</span
              >
            </div>
            <div class="flex items-center pl-4 pr-4">
              <!-- <span class="fsize12">Previous Week</span> -->
              <span
                class="text-md font-medium whitespace-no-wrap month-text22"
                @click.stop="handleThisWeek"
                :class="{
                  'hover:underline cursor-pointer':
                    new Date().getTime() <= firstDay.getTime() ||
                    new Date().getTime() >= lastDay.getTime(),
                }"
              >
                This Week
              </span>
            </div>
            <div class="flex items-center cursor-pointer" @click="setWeek(7)">
              <span class="fsize14 mob-fsize13 hover:underline">Next Week</span>
              <feather-icon
                icon="ChevronRightIcon"
                svgClasses="w-6 h-6 m-1"
                class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                title="Next Week"
              />
            </div>
          </div>

          <br />
        </div>
      </template>
    </CalendarHeader>
    <div>
      <div class="ecomerce-table-w tableFixHead lg:px-10 md:px-10">
        <table>
          <thead class="cust-bluecolor text-white cust-tbl-th">
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>
                <div class="flex justify-center items-center">
                  <b-form-select
                    class="select-inside-b"
                    v-model="country"
                    :options="countries"
                  />
                </div>
              </th>
              <th>Event</th>
              <th>
                <div class="flex justify-center items-center">
                  <b-form-select
                    class="select-inside-b"
                    v-model="priority"
                    :options="optionPriority"
                  />
                </div>
              </th>
              <th>
                <div class="flex justify-center items-center">
                  <b-form-select
                    class="select-inside-b"
                    v-model="actualFilter"
                    :options="optionActualFilter"
                  />
                </div>
              </th>
              <th>Forecast</th>
              <th>Previous</th>
              <!-- <th>Note</th> -->
            </tr>
          </thead>

          <tbody class="cust-tbl-td">
            <tr
              v-for="(item, index) in ecoCalendarEvents"
              :key="'item-' + index"
              v-bind="dateID(item.date, index)"
            >
              <td class="text-center font-semibold">
                {{ setDate(item.date, index) }}
              </td>
              <td class="text-center">
                {{ new Date(item.date * 1000).toLocaleTimeString() }}
              </td>
              <td class="text-center">
                <country-flag :country="setFlag(item.country)" />
              </td>
              <td
                @click.stop="handleClick(item)"
                class="cursor-pointer text-hover"
              >
                <div class="flex justify-between p-2">
                  <p>
                    {{ item.description }}
                  </p>
                  <!-- <feather-icon
                    icon="BellIcon"
                    badge="2"
                    class="cursor-pointer"
                  ></feather-icon> -->
                </div>
              </td>

              <td>
                <div class="webkit-center">
                  <p
                    class="cust-xs-btn cust-bluecolor"
                    size="small"
                    :class="{
                      'bg-primary': item.importance === 'High',
                      'bg-calendar-light-blue': item.importance === 'Low',
                    }"
                  >
                    {{ item.importance }}
                  </p>
                </div>
              </td>
              <td class="text-center">
                {{ item.actualValue }}
                {{ item.valueType && item.actualValue ? item.valueType : "" }}
              </td>
              <td class="text-center">
                {{ item.forecastValue }}
                {{ item.valueType && item.forecastValue ? item.valueType : "" }}
              </td>
              <td class="text-center">
                {{ item.previousValue }}
                {{ item.valueType && item.previousValue ? item.valueType : "" }}
              </td>
              <!-- <td>
                <feather-icon
                  icon="BarChartIcon"
                  svgClasses="w-6 h-6 m-1"
                  class="cursor-pointer hover:bg-grey hover:text-white rounded-full w-8 h-8"
                  v-if="item.isStory === 'true'"
                  @click.stop="handleClick(item)"
                />
              </td> -->
            </tr>
          </tbody>
        </table>
        <p class="pt-4 pb-2">
          <span class="font-semibold"> Data Source:</span> Netdania
        </p>
        <p class="fsize8 line-15 whitespace-pre-line pb-2">
          <span class="font-bold">Disclaimer:</span>
          <!-- In the preparation of the material content on this page, we have used
          information that is publicly available and is not on real time in
          nature. Information gathered & material used in this document is from
          reliable sources. -->
          In the preparation of the material content on this page, we have used
          information that is publicly available and is static in nature.
          Information gathered & material used in this document is from reliable
          sources.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import CalendarHeader from "../components/CalendarHeader";
import HistoricalDataSidebar from "../components/HistoricalDataSidebar";

import tokenMixin from "@/Client/Kotak/mixins/tokenMixin";
import treasuryAnalyticsMixin from "@/Client/Kotak/mixins/treasuryAnalyticsMixin";

export default {
  mixins: [tokenMixin, treasuryAnalyticsMixin],
  components: {
    CalendarHeader,
    CountryFlag,
    HistoricalDataSidebar,
  },
  data() {
    return {
      countries: [
        { value: null, text: "Country", disabled: true },
        {
          value: "All",
          text: "All",
        },
        {
          value: "AUSTRALIA",
          text: "Australia",
        },
        {
          value: "CANADA",
          text: "Canada",
        },
        {
          value: "CHINA",
          text: "China",
        },
        {
          value: "EURO ZONE",
          text: "Euro Zone",
        },
        {
          value: "JAPAN",
          text: "Japan",
        },
        {
          value: "NEW ZEALAND",
          text: "New Zealand",
        },
        {
          value: "SWITZERLAND",
          text: "Switzerland",
        },
        {
          value: "UNITED KINGDOM",
          text: "United Kingdom",
        },
        {
          value: "UNITED STATES",
          text: "United States",
        },
      ],

      country: null,
      priority: null,
      optionPriority: [
        { value: null, text: "Importance", disabled: true },
        {
          value: "All",
          text: "All",
        },
        {
          value: "High",
          text: "High",
        },
        {
          value: "Medium",
          text: "Medium",
        },
        {
          value: "Low",
          text: "Low",
        },
      ],
      actualFilter: null,
      optionActualFilter: [
        { value: null, text: "Actual", disabled: true },
        {
          value: "All",
          text: "All",
        },
        {
          value: "Actual",
          text: "Actual Values",
        },
      ],
      showDate: new Date(),
      firstDay: new Date(),
      lastDay: new Date(),
      flags: {
        AUSTRALIA: "au",
        CANADA: "ca",
        CHINA: "cn",
        "EURO ZONE": "eu",
        JAPAN: "jp",
        "NEW ZEALAND": "nz",
        SWITZERLAND: "ch",
        "UNITED KINGDOM": "gb",
        "UNITED STATES": "us",
        "SAUDI ARABIA": "sa",
        "RUSSIAN FEDERATION": "ru",
        INDIA: "in",
        TURKEY: "tr",
        ITALY: "it",
        FRANCE: "fr",
        MEXICO: "mx",
        BRAZIL: "br",
        INDONESIA: "id",
        "KOREA, REPUBLIC OF": "kr",
        "SOUTH AFRICA": "za",
      },
      isActive: false,
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Previous",
            borderWidth: 0,
            pointHitRadius: 0,
            borderColor: "#7dd5ff",
            fill: false,
          },
          {
            data: [],
            label: "Actual",
            borderWidth: 0,
            pointHitRadius: 0,
            borderColor: "#0d3e72",
            fill: false,
          },
          {
            data: [],
            label: "Forecast",
            borderWidth: 0,
            pointHitRadius: 0,
            borderColor: "#ec1f30",
            fill: false,
          },
        ],
      },
      sidebarTableData: [],
      sidebarInfo: {},
    };
  },
  watch: {
    lastDay() {
      this.getData();
    },
    futureEvents() {
      this.futureEvents.forEach((el, index) => {
        window.setTimeout(() => {
          // this.$swal(el.description).then(() => {
          //   this.futureEvents.splice(index, 1);
          //   this.getFirstDayOfWeek();
          //   this.getLastDayOfWeek();
          // });
          this.$vs.notify({
            title: "Event",
            text: el.description,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "primary",
            position: "top-right",
            time: 5000,
          });
          this.futureEvents.splice(index, 1);
          this.getFirstDayOfWeek();
          this.getLastDayOfWeek();
        }, el.date * 1000 - new Date().getTime());
      });
    },
    chartCondition() {
      return (item) => {
        return (
          item.isStory === "true" &&
          item.actualValue &&
          item.forecastValue &&
          item.previousValue
        );
      };
    },
    userEventNotification(val) {
      const [futureEvents, eventNotifications] = val.split("|");

      if (Number(futureEvents) && Number(eventNotifications)) {
        const eventIDs = this.eventNotifications.map(({ eventID }) => eventID);

        const data = this.futureEvents.filter((x) =>
          eventIDs.includes(x.storyID)
        );

        this.$store.commit("calendar/SET_ACTUAL_NOTIFICATIONS", data);
        this.actualNotifications.forEach((el, index) => {
          window.setTimeout(() => {
            this.$vs.notify({
              title: "Event",
              text: el.description,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "primary",
              position: "top-right",
              time: 5000,
            });
            this.actualNotifications.splice(index, 1);
            this.getFirstDayOfWeek();
            this.getLastDayOfWeek();
          }, el.date * 1000 - new Date().getTime());
        });
      }
    },
  },
  computed: {
    ecoCalendarEvents() {
      if (
        (this.country && this.country !== "All") ||
        (this.priority && this.priority !== "All") ||
        (this.actualFilter && this.actualFilter !== "All")
      ) {
        return this.$store.getters["calendar/getFilteredEvents"](
          this.country,
          this.priority,
          this.actualFilter
        );
      } else {
        return this.$store.state.calendar.events;
      }
    },
    futureEvents() {
      return this.$store.state.calendar.futureEvents;
    },
    eventNotifications() {
      return this.$store.state.calendar.eventNotifications;
    },
    actualNotifications() {
      return this.$store.state.calendar.actualNotifications;
    },
    firstDayTime() {
      const date = new Date(this.firstDay);
      return date.getTime() / 1000;
    },
    lastDayTime() {
      const date = new Date(this.lastDay);
      return date.getTime() / 1000;
    },
    userEventNotification() {
      return `${this.futureEvents.length}|${this.eventNotifications.length}`;
    },
    dateID() {
      return (date, index) => {
        return this.setDate(date, index)
          ? { id: this.setDate(date, index) }
          : "";
      };
    },
  },
  methods: {
    getFirstDayOfWeek() {
      const date = this.firstDay;
      const day = date.getDay();
      const diff = date.getDate() - day + (day == 0 ? -6 : 1);
      this.firstDay = new Date(date.setDate(diff));
    },
    setMonthFromSelect(date) {
      this.showDate = date;
    },
    getLastDayOfWeek() {
      const date = this.lastDay;
      const day = date.getDay();
      const diff = date.getDate() + (6 - day);
      this.lastDay = new Date(date.setDate(diff));
    },

    setWeek(value) {
      this.firstDay = new Date(
        this.firstDay.setDate(this.firstDay.getDate() + value)
      );
      this.lastDay = new Date(
        this.lastDay.setDate(this.lastDay.getDate() + value)
      );
    },

    async getData() {
      const startDate = new Date(this.firstDay.setHours(0, 0, 0, 0));
      const endDate = new Date(this.lastDay.setHours(0, 0, 0, 0));

      const payload = {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
      };

      try {
        this.$vs.loading();

        await this.$store.dispatch("calendar/getSearch", payload);

        this.showCurrentDateData();

        this.$vs.loading.close();
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },
    formatDate(date) {
      return new Date(date).toISOString().replace("T", "%20").replace("Z", "");
    },
    setFlag(country) {
      return this.flags[country];
    },
    setDate(date, index) {
      const d = this.formatEventDate(date);
      const previousEvent =
        index !== 0 ? this.ecoCalendarEvents[index - 1] : null;

      if (
        index === 0 ||
        (previousEvent && d !== this.formatEventDate(previousEvent.date))
      ) {
        return d;
      } else {
        return "";
      }
    },
    formatEventDate(date) {
      if (!date) return;
      return new Date(date * 1000).toLocaleDateString("en-GB");
    },
    toggleSidebar(val = false) {
      this.isActive = val;
      if (!val) {
        this.chartData = {
          labels: [],
          datasets: [
            {
              data: [],
              label: "Previous",
              borderWidth: 0,
              pointHitRadius: 0,
              borderColor: "#7dd5ff",
              fill: false,
            },
            {
              data: [],
              label: "Actual",
              borderWidth: 0,
              pointHitRadius: 0,
              borderColor: "#0d3e72",
              fill: false,
            },
            {
              data: [],
              label: "Forecast",
              borderWidth: 0,
              pointHitRadius: 0,
              borderColor: "#ec1f30",
              fill: false,
            },
          ],
        };
        this.sidebarInfo = {};
      }
    },
    async handleClick({
      description,
      parentID,
      actualValue,
      forecastValue,
      previousValue,
      valueType,
      storyID,
      date,
    }) {
      if (!parentID) return;
      const payload = {
        storyID: parentID,
      };

      try {
        this.$vs.loading();

        const data = await this.$store.dispatch("calendar/getHistory", payload);

        this.$vs.loading.close();
        this.sidebarInfo.title = description;
        this.sidebarInfo.actual = actualValue;
        this.sidebarInfo.previous = forecastValue;
        this.sidebarInfo.forecast = previousValue;
        this.sidebarInfo.type = valueType;
        this.sidebarInfo.storyID = storyID;
        this.sidebarInfo.date = date;
        this.setData(data);

        this.toggleSidebar(true);
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },
    setData(payload) {
      const data = JSON.parse(payload._text);
      this.sidebarInfo.description = data.f2;
      this.sidebarTableData = data.f11;
      data.f11.forEach((el) => {
        this.chartData.labels.push(
          new Date(el.f12 * 1000).toLocaleDateString("en-GB")
        );
        this.chartData.datasets[0].data.push(el.f6);
        this.chartData.datasets[1].data.push(el.f7);
        this.chartData.datasets[2].data.push(el.f8);
      });
    },
    handleThisWeek() {
      if (
        new Date().getTime() >= this.firstDay.getTime() &&
        new Date().getTime() <= this.lastDay.getTime()
      )
        return;
      this.firstDay = new Date();
      this.lastDay = new Date();
      this.getFirstDayOfWeek();
      this.getLastDayOfWeek();
    },
    getEventNotifications() {
      if (!this.token) return;
      const now = new Date();
      const today = new Date().toISOString().slice(0, 10);
      let tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow = tomorrow.toISOString().slice(0, 10);
      const payload = {
        startDate: today,
        endDate: tomorrow,
        userID: this.token.uuid,
      };

      this.$store.dispatch("calendar/getNotifications", payload);
    },
    scrollToDate(date) {
      const time = new Date(date).getTime();
      const toDate = this.formatEventDate(time / 1000);
      // Find First DOM element of a particular date
      const element = document.getElementById(toDate);
      return element;
    },
    showCurrentDateData() {
      const today = new Date();
      let attempts = 3; //No of previous dates to search
      while (attempts > 0) {
        today.setDate(today.getDate() - (3 - attempts)); //To Get current or previous dates
        const element = this.scrollToDate(today);
        if (element) {
          //Scroll to previous element as current date element gets hidden behind table header
          const previous = element.previousElementSibling;
          previous.scrollIntoView();
          attempts = 0;
        } else {
          attempts--;
        }
      }
      //Scroll to top on weeks change
      if (!this.scrollToDate(today)) {
        const element = document.getElementsByClassName("cust-tbl-td");
        const child = element[0].firstElementChild;
        const next = child.nextElementSibling;
        next.scrollIntoView({ block: "center" });
      }
    },
  },
  created() {
    this.getFirstDayOfWeek();
    this.getLastDayOfWeek();
  },
  mounted() {
    this.getEventNotifications();
    this.trackPage("calendar/eco");
  },
};
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #0d3e7217;
}
.cust-bluecolor {
  background: #0d3e72;
}
.cust-xs-btn {
  font-size: 11px;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  width: 70px;
  text-align: center;
}
.month-text22 {
  font-size: 18px !important;
  color: #0d3e72;
}
.ecomerce-table-w {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.cust-tbl-th th {
  font-weight: 700;
  text-align: center;
}
.cust-tbl-th th {
  font-size: 15px;
}
.cust-tbl-td td {
  font-size: 13px;
}
.webkit-center {
  text-align: -webkit-center;
}
.bg-calendar-light-blue {
  background: #7dd5ff !important;
} /* JUST COMMON TABLE STYLES... */

th,
td {
  z-index: 999;
}
th {
  background: #0d3e72;
}
.tableFixHead {
  overflow: auto;
  height: 90vh;
}
.tableFixHead::-webkit-scrollbar {
  width: 0;
}

.tableFixHead thead th {
  position: sticky;
  top: -1px;
}

@media (min-width: 1080px) {
  .select-inside-b {
    font-size: 15px !important;
    width: 100% !important;
    background: #0d3e72;
    color: #fff;
    padding: 7px 10px !important;
    height: 37px !important;
    font-weight: 700;
    border: 1px solid #ced4da61 !important;
  }
}

@media (max-width: 1079px) {
  .select-inside-b {
    font-size: 15px !important;
    width: auto !important;
    background: #0d3e72;
    color: #fff;
    padding: 7px 10px !important;
    height: 37px !important;
    font-weight: 700;
    border: 1px solid #ced4da61 !important;
  }
}
</style>
