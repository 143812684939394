import jwt from "jsonwebtoken";

export default {
  data() {
    return {
      token: {}
    };
  },
  mounted() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  }
};
