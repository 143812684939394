<template>
  <div>
    <chartjs-component-line-chart
      :height="250"
      :data="data"
      :options="options"
    ></chartjs-component-line-chart>
  </div>
</template>

<script>
import ChartjsComponentLineChart from "@/Client/Kotak/charts/ChartjsComponentLineChart.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ChartjsComponentLineChart
  },
  data() {
    return {
      options: {
        title: {
          display: true,
          text: ""
        }
      }
    };
  }
};
</script>
