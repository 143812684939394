var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"flex justify-between items-center mb-6"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"font-bold text-xl mr-2"},[_vm._v("Event - ")]),_c('p',{staticClass:"font-medium text-xl"},[_vm._v(_vm._s(_vm.title))])]),_c('EventNotification',{attrs:{"storyID":_vm.storyID,"date":_vm.date},on:{"close-sidebar":_vm.handleCloseSidebar}})],1),_c('div',[_c('div',{staticClass:"mb-4 flex"},[(_vm.actual && _vm.previous)?_c('feather-icon',{class:{
          'text-success': _vm.actual > _vm.previous,
          'text-danger': _vm.actual < _vm.previous
        },attrs:{"icon":_vm.actual > _vm.previous ? 'ArrowUpIcon' : 'ArrowDownIcon'}}):_vm._e(),_c('div',{staticClass:"flex flex-col ml-3 justify-center"},[_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"font-bold mr-4"},[_vm._v("Actual")]),_c('span',{class:{
              'text-success': _vm.actual && _vm.previous && _vm.actual > _vm.previous,
              'text-danger': _vm.actual && _vm.previous && _vm.actual < _vm.previous
            }},[_vm._v(_vm._s(_vm.actual)+" "+_vm._s(_vm.type && _vm.actual ? _vm.type : ""))])]),_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"mr-4"},[_vm._v("Previous")]),_c('span',[_vm._v(_vm._s(_vm.previous)+" "+_vm._s(_vm.type && _vm.previous ? _vm.type : ""))])]),_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"mr-4"},[_vm._v("Forecast")]),_c('span',[_vm._v(_vm._s(_vm.forecast)+" "+_vm._s(_vm.type && _vm.forecast ? _vm.type : ""))])])])],1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.description)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }