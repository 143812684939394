<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.data, this.options);
  }
};
</script>
<style>
.chartjs-render-monitor {
  width: 100% !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
</style>
