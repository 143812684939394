<template>
  <div v-if="canNotify">
    <feather-icon
      @click="handleClick"
      :icon="isNotified ? 'BellIcon' : 'BellOffIcon'"
      class="cursor-pointer"
    ></feather-icon>
  </div>
</template>

<script>
import tokenMixin from "@/Client/Kotak/mixins/tokenMixin";

export default {
  mixins: [tokenMixin],
  props: {
    storyID: {
      type: String,
      default: ""
    },
    date: {
      type: Number
    }
  },
  data() {
    return {
      bell: false
    };
  },
  computed: {
    isNotified() {
      return this.storyID
        ? this.$store.getters["calendar/getEventNotification"](this.storyID)
            .length > 0
        : false;
    },
    canNotify() {
      const time = new Date().getTime() / 1000;
      return this.date > time;
    }
  },
  methods: {
    handleClick() {
      if (this.token) {
        if (this.isNotified) {
          this.removeNotification();
        } else {
          this.addNotification();
        }
      } else {
        this.$emit("close-sidebar");
        this.$router.push({
          name: "page-kotak-login",
          params: {
            toRoute: "economic-calendar"
          }
        });
      }
    },
    async addNotification() {
      const payload = {
        date: new Date(this.date * 1000).toISOString().slice(0, 10),
        eventID: this.storyID,
        userID: this.token.uuid
      };

      this.$vs.loading();

      await this.$store.dispatch("calendar/addNotification", payload);

      this.$vs.loading.close();
    },
    async removeNotification() {
      const data = this.$store.getters["calendar/getEventNotification"](
        this.storyID
      );

      const payload = {
        id: data[0]._id
      };

      this.$vs.loading();

      await this.$store.dispatch("calendar/removeNotification", payload);

      this.$vs.loading.close();
    }
  }
};
</script>
