<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div class="flex">
        <span class="font-bold text-xl mr-2">Event - </span>
        <p class=" font-medium text-xl ">{{ title }}</p>
      </div>
      <EventNotification
        :storyID="storyID"
        :date="date"
        @close-sidebar="handleCloseSidebar"
      />
    </div>
    <div>
      <div class="mb-4 flex">
        <feather-icon
          v-if="actual && previous"
          :icon="actual > previous ? 'ArrowUpIcon' : 'ArrowDownIcon'"
          :class="{
            'text-success': actual > previous,
            'text-danger': actual < previous
          }"
        ></feather-icon>
        <div class="flex flex-col ml-3 justify-center">
          <div class="flex justify-between">
            <p class="font-bold mr-4">Actual</p>
            <span
              :class="{
                'text-success': actual && previous && actual > previous,
                'text-danger': actual && previous && actual < previous
              }"
              >{{ actual }} {{ type && actual ? type : "" }}</span
            >
          </div>
          <div class="flex justify-between">
            <p class="mr-4">Previous</p>
            <span>{{ previous }} {{ type && previous ? type : "" }}</span>
          </div>
          <div class="flex justify-between">
            <p class="mr-4">Forecast</p>
            <span>{{ forecast }} {{ type && forecast ? type : "" }}</span>
          </div>
        </div>
      </div>
      <p v-html="description" class=""></p>
    </div>
  </div>
</template>

<script>
import EventNotification from "./EventNotification";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    actual: {
      type: String,
      default: ""
    },
    previous: {
      type: String,
      default: ""
    },
    forecast: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    storyID: {
      type: String,
      default: ""
    },
    date: {
      type: Number
    }
  },
  components: {
    EventNotification
  },
  data() {
    return {
      bell: false
    };
  },
  methods: {
    handleCloseSidebar() {
      this.$emit("close-sidebar");
    }
  }
};
</script>
