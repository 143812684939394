<template>
  <div class="ecomerce-table-w">
    <table>
      <thead class="cust-bluecolor text-white cust-tbl-th">
        <tr>
          <th>Date</th>
          <th>Actual</th>
          <th>Forecast</th>
          <th>Previous</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="'item-' + index">
          <td>
            {{ new Date(item.f12 * 1000).toLocaleDateString("en-GB") }}
          </td>
          <td>{{ item.f6 }}</td>
          <td>{{ item.f7 }}</td>
          <td>{{ item.f8 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
};
</script>
