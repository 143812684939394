<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="sidebarx chart-sidebar"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="flex px-4 sidebar-topfixpart bg-primary">
      <feather-icon
        icon="ChevronLeftIcon"
        @click="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
      <vs-button class="h2-class pd-10 cust-remove-boxsh">
        HISTORY
      </vs-button>
    </div>
    <div class="cust-mt-9per">
      <div class="p-6">
        <HistoricalDataInfo
          :title="info.title"
          :description="info.description"
          :actual="info.actual"
          :forecast="info.forecast"
          :previous="info.previous"
          :type="info.type"
          :storyID="info.storyID"
          :date="info.date"
          @close-sidebar="closeSidebar"
        />
        <p class="text-center font-semibold text-xl mt-8">Chart</p>
        <HistoricalData v-if="data.labels.length" :data="data" />
        <p class="text-center font-semibold text-xl mt-8 mb-4">History</p>
        <HistoricalDataTable :data="descendingTable" />
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";

import HistoricalData from "./HistoricalData";
import HistoricalDataTable from "./HistoricalDataTable";
import HistoricalDataInfo from "./HistoricalDataInfo";

export default {
  components: {
    HistoricalData,
    HistoricalDataTable,
    HistoricalDataInfo
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    table: {
      type: Array,
      default: () => []
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    $route() {
      this.isSidebarActiveLocal = false;
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.closeSidebar();
        }
      }
    },
    descendingTable() {
      return this.table.length ? this.sortedTable(this.table) : [];
    }
  },
  methods: {
    closeSidebar() {
      this.$emit("close-sidebar");
    },
    sortedTable(table) {
      return table.sort((a, b) => {
        return new Date(b.f12 * 1000) - new Date(a.f12 * 1000);
      });
    }
  }
};
</script>

<style>
.chart-sidebar > .vs-sidebar {
  width: 40% !important;
  max-width: 40% !important;
  z-index: 52010 !important;
}
.cust-mt-16per {
  margin-top: 16%;
}
</style>
